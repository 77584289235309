import { CustomerBM } from '@bm-js/h2o-shared';

export const parseCustomerName = (
  customerOrCustomerName?: Partial<CustomerBM> | string,
  fromServer?: boolean
) => {
  let stringToParse = '';
  if (typeof customerOrCustomerName === 'string')
    stringToParse = customerOrCustomerName || '';
  else stringToParse = customerOrCustomerName?.customerInformation?.name || '';
  if (fromServer) return stringToParse.split('_').join(' ') || '';
  else return stringToParse.split(' ').join('_') || '';
};
