import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import Button from './Button';
import DateInput from './DateInput';
import { CustomerBM, Notification, UserBM, UserFM } from '@bm-js/h2o-shared';

const notificationDefault = {
  dateForHandling: '' as unknown as Date,
  recipientId: '',
  textContent: '',
  textContentLong: [],
};

type Props = {
  users: (UserFM | UserBM)[];
  customer: CustomerBM;
  data?: Notification;
  handleSubmit: (
    payload: { notificationData: Notification },
    cb: () => void
  ) => void;
};

const NotificationForm = ({ handleSubmit, users, customer, data }: Props) => {
  const [formData, setFormData] =
    useState<Partial<Notification>>(notificationDefault);

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    const user = users.find((user) => user._id === formData.recipientId);
    const payload = {
      ...formData,
      customerId: customer._id,
      customerName: customer.customerInformation.name,
      subject: 'customerContactNotification',
      link: `/customers/single/?id=${customer._id}`,
      linkKey: 'notifications-popup',
      recipientFirstName: user?.firstName || '',
      recipientLastName: user?.lastName || '',
    };
    // @ts-ignore
    handleSubmit({ notificationData: payload }, () =>
      setFormData(notificationDefault)
    );
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <form onSubmit={onSubmit}>
      <p>Datum för hantering</p>
      <DateInput
        required
        value={formData.dateForHandling}
        name="dateForHandling"
        onChange={handleChange}
      />
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select
        required
        value={formData.recipientId}
        onChange={handleChange}
        name="recipientId"
      >
        <option disabled value="">
          Hanteras av
        </option>
        {users.map((user) => (
          <option key={user._id} value={user._id}>
            {user.firstName + ' ' + user.lastName}
          </option>
        ))}
      </select>
      <input
        required
        type="text"
        placeholder="Kontaktorsak"
        name="textContent"
        value={formData.textContent}
        onChange={handleChange}
      />
      <textarea
        required
        placeholder="Kommentar"
        name="textContentLong"
        value={formData.textContentLong?.join('\n')}
        onChange={(e) => {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value.split('\n'),
          });
        }}
      />
      <Button small type="submit">
        Spara notis
      </Button>
    </form>
  );
};

export default NotificationForm;
