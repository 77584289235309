import React from 'react';
import OverlayBox from './OverlayBox';
import { parseDate } from '../utils/parseDate';
import { Notification } from '@bm-js/h2o-shared';

type Props = {
  notification: Notification;
  close: () => void;
};

const NotificationPopup = ({ notification, close }: Props) => {
  const {
    dateForHandling,
    textContent,
    recipientFirstName,
    recipientLastName,
    textContentLong,
  } = notification;
  return (
    <div>
      <OverlayBox header={textContent} active close={close}>
        <p>
          <strong>Datum för hantering: </strong>
          {parseDate(dateForHandling)}
        </p>
        <p>
          <strong>Hanteras av: </strong>
          {`${recipientFirstName} ${recipientLastName}`}
        </p>
        <p>
          <strong>Kontaktorsak: </strong>
          {textContent}
        </p>
        <p>
          <strong>Kommentar:</strong>
        </p>
        {textContentLong?.map((t, i) => (
          <p key={t + i + 'notificationText'}>{t}</p>
        ))}
      </OverlayBox>
    </div>
  );
};

export default NotificationPopup;
