import { PersonBM } from '@bm-js/h2o-shared';
import { request } from './request';
import * as Sentry from '@sentry/gatsby';
import { GlobalState } from '../types/state.types';
import { Dispatch } from 'react';
import { Action } from '../types/dispatch.types';

const emptyPerson: PersonBM = {
  _id: '',
  name: '',
  email: '',
  phone: '',
  title: '',
  mobile: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  __v: 0,
};

export const getPersons = async ({
  dispatch,
  state,
}: {
  state: GlobalState;
  dispatch: Dispatch<Action>;
}) => {
  const { err, data } = await request<PersonBM[]>({
    method: 'GET',
    path: 'persons',
    state,
    dispatch,
  });

  if (err) {
    return { err };
  }

  return { persons: data };
};

export const getPersonById = (persons: PersonBM[], id: string): PersonBM => {
  if (!persons.length) return emptyPerson;
  const person = persons.find((person) => person._id === id);
  if (!person)
    Sentry.captureException(new Error(`Person with id ${id} not found`));
  return person || emptyPerson;
};
